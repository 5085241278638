import {locale as localeIt} from './locale/it';
import {locale as localeEn} from './locale/en';

function getBrowserLang()
{
 if (navigator.languages != undefined) 
  return navigator.languages[0]; 
  else 
 return navigator.language;
}

export const defaultLocale = (getBrowserLang() == 'it-IT') ? 'it': 'en';

export function localeGetText (locale = 'en', key) {
  const currentLocale = (locale == 'en') ? localeEn : localeIt;
  return (currentLocale.hasOwnProperty(key)) ? currentLocale[key] : null;
}

export function handleLocale (locale = 'en', callback) {
  const currentLocale = (locale == 'en') ? localeEn : localeIt;
  qsa('[data-i18n]').forEach(function(item){
    if(currentLocale.hasOwnProperty(item.dataset.i18n))
      item.innerHTML = currentLocale[item.dataset.i18n];
  })
  qsa('[data-locale]').forEach(function(localeEl) {
    if(localeEl.dataset.locale == locale)
      localeEl.classList.add('active');
    else
      localeEl.classList.remove('active');
  })
  callback(currentLocale);
}

export function registeri18nHandler(callback){
  return function(){
    callback();
  }
}