const images = require('../images/portfolio/*.*');

//import { isWEBPAvailable } from "./webp-detect";


export function handleClientsWall(callback){
  //const hasWebp = isWEBPAvailable();
  //const path = './assets/images/portfolio';
  qsa('.clients-wall div').forEach((project) => {
    //const imageSrc = (hasWebp) ? images[project.dataset.project].webp : images[project.dataset.project].png;
    const imageSrc = images[project.dataset.project].png;
    project.classList.add('lazy');
    project.setAttribute('data-bg', `url('${imageSrc}')`);
  })
  callback();
}