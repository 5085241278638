/** Scrolling "Gone Up" */
var prevScrollpos = window.pageYOffset;
export function handlePageScroll_GoneUp() {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos >= currentScrollPos) {
    qs('header').classList.remove('gone-up');
  } else {
    qs('header').classList.add('gone-up');
  }
  prevScrollpos = currentScrollPos;
}

/** Scrolling "transparent" */
var heroScrollThreshold = 0.9; // Percentuale di altezza della window dopo il quale l'header perde la trasparenza
export function handlePageScroll_Transparent() {
    var heroScrollPosition = window.innerHeight * heroScrollThreshold;
    var currentScrollPos = window.pageYOffset;
    if(currentScrollPos < heroScrollPosition) {
        qs('header').classList.add('transparent');
    } else {
        qs('header').classList.remove('transparent');
    }
}

/** Scrolling "short" */
export function handlePageScroll_Short() {
    if(window.pageYOffset > 0) {
        qs('header').classList.add('short');
    } else {
        qs('header').classList.remove('short');
    }
}

/** Scrolling "short" + "transparent" */
export function handlePageScroll() {
    const headerEl = qs('header');
    if(headerEl.classList.contains('mode-gone-up')){
        handlePageScroll_GoneUp();
    } else if (headerEl.classList.contains('mode-transparent')){
        handlePageScroll_Transparent();
    } else if (headerEl.classList.contains('mode-short')){
        handlePageScroll_Short();
    }
    //handlePageScroll_Transparent();
}

export const scrollAnimate = function(el: HTMLElement, scrollDirection: 'scrollTop' | 'scrollRight' | 'scrollBottom' | 'scrollLeft', start: number, end: number, duration: number, easingType: string) {

  return new Promise(function (resolve) {
      let easings = {
          // x = extra
          // t = current time or position
          // b = begin value
          // c = change or delta of value
          // d = duration / total time or position
          linear: function (x, t, b, c, d) {
             return c * (t / d) + b;
             },
          easeInQuad: function (x, t, b, c, d) {
              return c * (t /= d) * t + b;
          },
          easeOutQuad: function (x, t, b, c, d) {
              return -c * (t /= d) * (t - 2) + b;
          },
          easeInOutQuad: function (x, t, b, c, d) {
              if ((t /= d / 2) < 1) return c / 2 * t * t + b;
              return -c / 2 * ((--t) * (t - 2) - 1) + b;
          },
          easeInCubic: function (x, t, b, c, d) {
              return c * (t /= d) * t * t + b;
          },
          easeOutCubic: function (x, t, b, c, d) {
              return c * ((t = t / d - 1) * t * t + 1) + b;
          },
          easeInOutCubic: function (x, t, b, c, d) {
              if ((t /= d / 2) < 1) return c / 2 * t * t * t + b;
              return c / 2 * ((t -= 2) * t * t + 2) + b;
          },
          easeInQuart: function (x, t, b, c, d) {
              return c * (t /= d) * t * t * t + b;
          },
          easeOutQuart: function (x, t, b, c, d) {
              return -c * ((t = t / d - 1) * t * t * t - 1) + b;
          },
          easeInOutQuart: function (x, t, b, c, d) {
              if ((t /= d / 2) < 1) return c / 2 * t * t * t * t + b;
              return -c / 2 * ((t -= 2) * t * t * t - 2) + b;
          },
          easeInQuint: function (x, t, b, c, d) {
              return c * (t /= d) * t * t * t * t + b;
          },
          easeOutQuint: function (x, t, b, c, d) {
              return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
          },
          easeInOutQuint: function (x, t, b, c, d) {
              if ((t /= d / 2) < 1) return c / 2 * t * t * t * t * t + b;
              return c / 2 * ((t -= 2) * t * t * t * t + 2) + b;
          },
          easeInSine: function (x, t, b, c, d) {
              return -c * Math.cos(t / d * (Math.PI / 2)) + c + b;
          },
          easeOutSine: function (x, t, b, c, d) {
              return c * Math.sin(t / d * (Math.PI / 2)) + b;
          },
          easeInOutSine: function (x, t, b, c, d) {
              return -c / 2 * (Math.cos(Math.PI * t / d) - 1) + b;
          },
          easeInExpo: function (x, t, b, c, d) {
              return (t == 0) ? b : c * Math.pow(2, 10 * (t / d - 1)) + b;
          },
          easeOutExpo: function (x, t, b, c, d) {
              return (t == d) ? b + c : c * (-Math.pow(2, -10 * t / d) + 1) + b;
          },
          easeInOutExpo: function (x, t, b, c, d) {
              if (t == 0) return b;
              if (t == d) return b + c;
              if ((t /= d / 2) < 1) return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
              return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
          },
          easeInCirc: function (x, t, b, c, d) {
              return -c * (Math.sqrt(1 - (t /= d) * t) - 1) + b;
          },
          easeOutCirc: function (x, t, b, c, d) {
              return c * Math.sqrt(1 - (t = t / d - 1) * t) + b;
          },
          easeInOutCirc: function (x, t, b, c, d) {
              if ((t /= d / 2) < 1) return -c / 2 * (Math.sqrt(1 - t * t) - 1) + b;
              return c / 2 * (Math.sqrt(1 - (t -= 2) * t) + 1) + b;
          },
          easeInElastic: function (x, t, b, c, d) {
              var s = 1.70158;
              var p = 0;
              var a = c;
              if (t == 0) return b;
              if ((t /= d) == 1) return b + c;
              if (!p) p = d * .3;
              if (a < Math.abs(c)) {
                  a = c;
                  s = p / 4;
              }
              else s = p / (2 * Math.PI) * Math.asin (c / a);
              return -(a * Math.pow(2, 10 * (t -= 1)) * Math.sin((t * d - s) * (2 * Math.PI) / p)) + b;
          },
          easeOutElastic: function (x, t, b, c, d) {
              var s = 1.70158;
              var p = 0;
              var a = c;
              if (t == 0) return b;
              if ((t /= d) == 1) return b + c;
              if (!p) p = d * .3;
              if (a < Math.abs(c)) {
                  a = c;
                  s = p / 4;
              }
              else s = p / (2 * Math.PI) * Math.asin (c / a);
              return a * Math.pow(2, -10 * t) * Math.sin((t * d - s) * (2 * Math.PI) / p) + c + b;
          },
          easeInOutElastic: function (x, t, b, c, d) {
              var s = 1.70158;
              var p = 0;
              var a = c;
              if (t == 0) return b;
              if ((t /= d / 2) == 2) return b + c;
              if (!p) p = d * (.3 * 1.5);
              if (a < Math.abs(c)) {
                  a = c;
                  s = p / 4;
              }
              else s = p / (2 * Math.PI) * Math.asin (c / a);
              if (t < 1) return -.5 * (a * Math.pow(2, 10 * (t -= 1)) * Math.sin((t * d - s) * (2 * Math.PI) / p)) + b;
              return a * Math.pow(2, -10 * (t -= 1)) * Math.sin((t * d - s) * (2 * Math.PI) / p) * .5 + c + b;
          },
          easeInBack: function (x, t, b, c, d, s) {
              if (s == undefined) s = 1.70158;
              return c * (t /= d) * t * ((s + 1) * t - s) + b;
          },
          easeOutBack: function (x, t, b, c, d, s) {
              if (s == undefined) s = 1.70158;
              return c * ((t = t / d - 1) * t * ((s + 1) * t + s) + 1) + b;
          },
          easeInOutBack: function (x, t, b, c, d, s) {
              if (s == undefined) s = 1.70158;
              if ((t /= d / 2) < 1) return c / 2 * (t * t * (((s *= (1.525)) + 1) * t - s)) + b;
              return c / 2 * ((t -= 2) * t * (((s *= (1.525)) + 1) * t + s) + 2) + b;
          },
          easeOutBounce: function (x, t, b, c, d) {
              if ((t /= d) < (1 / 2.75)) {
                  return c * (7.5625 * t * t) + b;
              } else if (t < (2 / 2.75)) {
                  return c * (7.5625 * (t -= (1.5 / 2.75)) * t + .75) + b;
              } else if (t < (2.5 / 2.75)) {
                  return c * (7.5625 * (t -= (2.25 / 2.75)) * t + .9375) + b;
              } else {
                  return c * (7.5625 * (t -= (2.625 / 2.75)) * t + .984375) + b;
              }
          }
      };
      let tween = function (start, end, duration, easing) {
          let delta = end - start;
          let startTime;
          if (window.performance && window.performance.now) {
              startTime = performance.now();
          }
          else if (Date.now) {
              startTime = Date.now();
          }
          else {
              startTime = new Date().getTime();
          }
          let tweenLoop = function (time) {
              let t = (!time ? 0 : time - startTime);
              let factor = easing(null, t, 0, 1, duration);
              el[scrollDirection] = (t >= duration) ? end : start + delta * factor;
              if (t < duration && el[scrollDirection ]!= end) {
                  requestAnimationFrame(tweenLoop);
              }
              else {
                  requestAnimationFrame(function () {
                      setTimeout(function () {
                          resolve();
                      }, 120);
                  });
              }
          };
          tweenLoop();
      };
      tween(start, end, duration, easings[easingType]);
  });
}