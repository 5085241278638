export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
export function checkCookie(cname) {
  var cookieValue = getCookie(cname);
  if (!!cookieValue) {
   return true;
  } else {
    return false;
  }
}

function cookiePolicyClose() {
  if(document.getElementById('cookie-policy')) {
    document.getElementById('cookie-policy').remove();
    setCookie('cookie-policy', '1', 365);
  }
}

export function cookiePolicy(){
  document.getElementById('cookie-policy').style.display = 'block';
  const once = {
    once : true
  };
  window.addEventListener('scroll', cookiePolicyClose, once);
  window.addEventListener('click', cookiePolicyClose, once);
  window.addEventListener('resize', cookiePolicyClose, once);
  window.addEventListener('cut', cookiePolicyClose, once);
  window.addEventListener('copy', cookiePolicyClose, once);
  window.addEventListener('keydown', cookiePolicyClose, once);
  window.addEventListener('keypress', cookiePolicyClose, once);
  window.addEventListener('keyup', cookiePolicyClose, once);
  window.addEventListener('dblclick', cookiePolicyClose, once);
  window.addEventListener('wheel', cookiePolicyClose, once);
  window.addEventListener('contextmenu', cookiePolicyClose, once);
  window.addEventListener('dragstart', cookiePolicyClose, once);
}