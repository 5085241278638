export function adaptCards() {
  const cardsMap = {};
  qsa('.card').forEach(function(card) {
    card.qs('p').style.height = 'auto';
    if(!cardsMap.hasOwnProperty(card.dataset.referrer)) {
      cardsMap[card.dataset.referrer] = [];
    }
    cardsMap[card.dataset.referrer].push(card);
  });
  for(let referrerIndex in cardsMap) {
    let referrer = cardsMap[referrerIndex];
    var maxHeight = referrer.map((card) => card.qs('p').clientHeight).reduce(function(a, b) {
      return Math.max(a, b);
    });
    referrer.forEach((card) => card.qs('p').style.height = maxHeight + 'px');
  }
}