import { scrollAnimate } from "./scrolling";

const onIntersection = (entries) => {
  for (const entry of entries) {
    if (entry.isIntersecting) {
      autoSlideStart(entry.target);
      observer.unobserve(entry.target);
    }
  };
}

const observer = new IntersectionObserver(onIntersection);

const autoSlideStart = (domEl) => {
  const amountToSlide = domEl.scrollWidth - domEl.clientWidth;
  const timing = amountToSlide / domEl.dataset.speed * 1000;
  if(domEl.dataset.direction == 'left-to-right') {
    scrollAnimate(domEl, 'scrollLeft', 0, amountToSlide, timing, 'linear');
  } else {
    scrollAnimate(domEl, 'scrollLeft', amountToSlide, 0, timing, 'linear');
  }
}

export const autoSlide = (domEl) => {
  if('IntersectionObserver' in window){
    observer.observe(domEl);
  }
  else {
    autoSlideStart(domEl);
  }
}