export function modal(title, text) {
    document.getElementById('modal-overlay').addEventListener('click', () => {
        document.getElementById('modal').classList.remove('active');
    }, {
        once : true
    });
    document.getElementById('modal').querySelector('.close-btn').addEventListener('click', () => {
        document.getElementById('modal').classList.remove('active');
    }, {
        once : true
    });
    document.getElementById('modal').querySelector('h2').innerHTML = title;
    document.getElementById('modal').querySelector('p').innerHTML = text;
    document.getElementById('modal').classList.add('active');
}