import AOS from 'aos';
import { throttle } from 'lodash';
import { handlePageScroll } from './scrolling';
import { autoSlide } from './auto-slider';
import { adaptCards } from './cards';
import LazyLoad from "vanilla-lazyload";
import { type, type as loop} from '@camwiegert/typical';
import { handleLocale, registeri18nHandler, defaultLocale, localeGetText } from './i18n';
import { handleClientsWall } from './clients-wall';
import { cookiePolicy, checkCookie, setCookie } from './cookie-policy';
import { modal } from './modal';

window.onload = () => {
  if(!checkCookie('cookie-policy'))
    cookiePolicy();
    
  /* Things to do after every succesfull i18n */
  const handleLocalization = registeri18nHandler(function(locale){
    qsa('[data-typer]').forEach(function(item) {
      type(item, ...item.dataset.typer.split(',').map((item, index) => (index%2 == 0) ? parseInt(item) : item), loop);
    });
    adaptCards();
    if(!checkCookie('covid-banner')) {
      modal(localeGetText(locale, 'modalTitle'), localeGetText(locale, 'modalText'));
      setCookie('covid-banner', '1', 365);
    }
  });


  qsa('[menu-trigger]').forEach(function(hamb) {
    hamb.on('click', function() {
      this.parentNode.classList.toggle('in-hamburger');
    })
  });

  qsa('.menu-container button').forEach(function(item){
    item.on('click', function(){
      this.closest('header').classList.remove('active');
    });
  });

  qsa('[data-goto]').forEach(function(gotoTrigger) {
    gotoTrigger.on('click', function() {
      qs('[data-name="' + this.dataset.goto + '"]').scrollIntoView({
        block: "start",
        behavior: "smooth"
      });
    })
  })

  qsa('[data-locale]').forEach(function(localeTrigger) {
    localeTrigger.on('click', function() {
      handleLocale(this.dataset.locale, handleLocalization);
    })
  })

  qsa('.auto-slider').forEach(function(autoSlider) {
    autoSlide(autoSlider);
  });

  window.addEventListener('scroll', throttle(() => {
    handlePageScroll();
  }, 100, { leading: false, trailing: true }));

  window.addEventListener('resize', throttle(() => {
    adaptCards();
  }, 100, { leading: false, trailing: true }));

  AOS.init();
  handlePageScroll();
  handleLocale(defaultLocale, handleLocalization);
  //handleProductsBlock();
  //handleProductBlockClick('retail');
  adaptCards();
  /*handleLocale('it');
  setTimeout(() => handleLocale('en'), 3000);*/

  window.lazyloadInstance = new LazyLoad({elements_selector: ".lazy"});
  //handleProjectGrid(function(){ window.lazyloadInstance.update()}.bind(this));
  handleClientsWall(function(){ window.lazyloadInstance.update()}.bind(this));

  // Fix Safari display: flex bug
  var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
               navigator.userAgent &&
               navigator.userAgent.indexOf('CriOS') == -1 &&
               navigator.userAgent.indexOf('FxiOS') == -1;
  if(isSafari){
    qsa('[data-name="our-products"] .product-row').forEach(function(productRow){
      productRow.classList.add('block');
      productRow.classList.remove('flex');
    })
    qsa('[data-name="our-products"] .products-row-with-inverted-images-desktop').forEach(function(image){
      image.classList.add('hidden');
    })
    qsa('[data-name="our-products"] .products-row-with-inverted-images-tablet').forEach(function(image){
      image.classList.remove('hidden');
      image.classList.add('hidden-on-mobile');
    })
  }
}