export const locale = {
    /* Section titles */
    titleOurCompetences: "Our Competences",
    titleOurProducts: "Our Products",
    titleContacts: "Contacts",
    titleOurCustomers: "Our Customers",
    titleOurCustomersShort: "Some Of Our Customers",
    titleOurValues: "Our Values",
    /* Generics */
    ctaEmailUs: `Email us! <div class="icon"><span class="icon-envelope-o"></span></div>`,
    ctaKnowMore: `Know More! <div class="icon"><span class="icon-chevron-right"></span>`,
    /* Hero */
    heroH1: `We create<br><span data-typer="1000,great,1000,funny,1000,lightweight,1000,easy,1000,your"></span>
    <span class="cursor" data-owner="typer-products"></span><br>products`,
    heroPhrase1: 'We build products people love.',
    heroPhrase2: 'You got big questions?',
    heroPhrase3: 'We got even bigger answers.',
    heroCtaLearnMore: 'Learn more about us <div class="icon"><span class="icon-chevron-down">',
    /* Our Competences */
    ourCompetences1Title: 'Directional Consultancy',
    ourCompetences1Text: `By understanding and comprehending your business needs, we can develop solutions allowing you to have complete control over your complete chain of events: Management Control, Processes Flow and Organization, Budget Management and everything in between.<br>Tailor-made to suite your needs.`,
    ourCompetences2Title: 'Digital Transformation',
    ourCompetences2Text: `Digital Transformation is the usage of technology to simplify and improve the organizational processes and to guarantee a smoother and simpler business management. We can help you during this process by providing industry 4.0 solutions you can leverage
    on for a better horizontal organizational and business development.`,
    ourCompetences3Title: 'ERP &amp; CRM',
    ourCompetences3Text: `By working with world-class solutions such as Microsoft Business Central and Dynamics NAV we can provide cutting-edge marketing, sales, operations and finance management.<br> We can also improve your customer experience via Dynamics
    365 for Customer Engagement as a CRM solution for your business.`,
    ourCompetences4Title: 'Data Analytics',
    ourCompetences4Text: `Our solutions combine the deep knowledge of the business with the most modern technologies and allow, through the collection and analysis of company data, to improve the decision-making process and create value in the organization.`,
    ourCompetences5Title: 'System Integration',
    ourCompetences5Text: `Have you ever been in a situation where you can't seem to find a connection between different parts of your business? We provide integrated solutions, allowing you to see and manage your business in a clear, linear and unified way.`,
    ourCompetences6Title: 'System Development',
    ourCompetences6Text: `We develop applications and products made to fit your needs. During this process, we always try to help you understand and identify your real problems and obstacles, using cutting-edge technologies and our experience to solve them.`,
    /* Our Values */
    ourValues1Title: 'Agile Approach',
    ourValues1Text: `We apply an Agile approach for our software development so our requirements and solutions evolve through the collaborative effort of self-organizing and cross-functional teams and you, enabling you to follow the birth of your product and its growth, giving
    it the right direction in every step.`,
    ourValues2Title: 'Quality Assurance',
    ourValues2Text: `By applying cutting-edge technology and best-in-class development patterns into every part of our products, we guarantee quality and resilience to your products, allowing you to keep using our solutions under any circumstance.`,
    ourValues3Title: 'Best Practices',
    ourValues3Text: `We apply best practices and consolidated procedures in every part of our job. From quality assurance to error checking, from development techniques and development roadmap to user experience. We create products for you we are be proud of.`,
    ourValues4Title: 'Advanced Technology',
    ourValues4Text: `We only use best-in-class technologies to provide you the best possible experience. By using cutting-edge tools, we provide you first-class, modern and future-proof solutions. With our wide range of development stack, we choose the best possible tools
    to build your solution.`,
    /* Our Products */
    ourProductsMyBizTitle: `The world-class single access point to your business.`,
    ourProductsMyBizText: `The web-based framemork to digitize and consolidate all your company processes and application modules.<br> Human Resources Management, Projects, Orders, Management Control, Budget, Documents, Workflow, Finance, Distribution, Retail
    and much, much more!`,
    ourProductsRetailTitle: `A full-fledged retail solution for both small and big shops.`,
    ourProductsRetailText: `Multi-cash &amp; multi-currency management comprehensive of a solid promotion system, invoice printing and management, loyalty cards, ticket history, gift card, refund and food stamps management.<br> Built to be solid, reliable, scalable,
    extremely configurable and easily customizable.`,
    ourProductsAWTitle: `A 360 degree solution to keep your business inside your pocket.`,
    ourProductsAWText: `From communication to employees working hours, manage everything from your phone and never lose focus about your business.<br> Chat with your employees (Both via chat and VoIP), manage leads, have a look at your calendar, manage your
    wallet and your projects, look at business analytics and more!<br> A custom-made solution which provides system integration, project management, customer relationship management, reporting and analytics. All in one app.`,
    /* News */
    newsAlexiaTitle: 'Proud to be sponsor of Alexia Barrier during the Vendée Globe.',
    newsAlexiaText: 'Alexia Barrier could be the absolute reference in terms of tenacity. When she engaged in her Vendée Globe 2020 project, she first counts on her immense determination to take the start. But this young woman has several assets to put forth : she gathered a group of companies, institutions and patrons around her project in order to acquire in February 2018 Le Pingouin - the IMOCA sailed by Catherine Chabaud on the 2000 Vendée Globe.',
    newsSpatiTitle: '29-31 January 2020, the fair for services and products for Ticino companies.',
    newsSpatiText: `At the Exhibition Center of Lugano, via Campo Marzio, the 1st edition of Spazio Ticino offers the possibility for professionals in different sectors, whether they work in both large and small companies or who are independent consultants and specialists, to find strategies, techniques, methods and contacts to get your business off the ground. The event will see the presence of more than 100 companies operating in the area.<br>
    Our Company will be present, in collaboration with Sisma Informatica S.A., at Stand 108 in the Information & Technology area.<br>
    Numerous special initiatives will take place during the event:<br>
    <blockquote>
            8 Conferences<br>
            10 Workshops<br>
            3 Important events: Team Building, Networking, comparison and exchange of views.
    </blockquote>
       Participating in Spazio Ticino is the right choice:
    <ul>
        <li>To meet suppliers of products and services by visiting the thematic areas of the fair.</li>
        <li>To learn more about safety and risk management in the company with professional speakers and thematic workshops.</li>
        <li>To explore the world of communication at 360 � by meeting web agencies, communication agencies, and listening to the conferences of communication experts.</li>
        <li>Find partners, contacts and support to open or start a business in Ticino.</li>
        <li>To deal with information & technology professionals to renew your business processes, save resources and increase productivity.</li>
        <li>To advertise better: more targeted and more profitable.</li>
        <li>To find the ideal suppliers to create successful business events.</li>
        <li>To find inspiration, share ideas and build a network.</li>
        <li>To save money: find new suppliers, optimized services, energy savings, tailor-made insurance plans and advice to save money by increasing productivity.</li>
    </ul>
    We would be pleased to welcome you to our stand during the days of the fair.<br>
    Participation is subject to online registration on the event website, at the following link: <a href="https://www.spati.ch/" target="_blank">www.spati.ch</a>.<br><br>
    Looking forward to meeting you at our stand,<br>
we send our best regards.<br><br>`,
    /* Footer */
    footerContactUs: `Contact us at <br class="hidden-on-desktop hidden-on-tablet">
    <a href="mailto:info@kfactor.ch" class="txt-primary">info@kfactor.ch</a>`,
    cookiePolicyBanner: `
    <div class="close-btn">x</div><br>
    This site or third-party tools used by this make use of cookies necessary for the operation and useful for the purposes described in the cookie policy.
     If you want to learn more or opt out of all or some cookies, see the <a style="text-decoration: underline; color: #fff; font-size: 14px; font-weight: 900;" href="./legal.html" target="_blank">cookie policy</a>.<br>
     By closing this banner, scrolling this page, clicking on a link or continuing navigation in any other way, you consent to the use of cookies.
    `,
    modalTitle: 'CoVid-19 Announcement',
    modalText: `
    KFactor Solutions sagl informs its customers that, despite the CoVid-19 emergency, the company remains 100% open and operational to be able to support you in this delicate situation. <br>
We are at your disposal for any request, even just for support or advice.`
}