export const locale = {
    /* Section titles */
    titleOurCompetences: "Le Competenze",
    titleOurProducts: "I Prodotti",
    titleContacts: "Contatti",
    titleOurCustomers: "I Clienti",
    titleOurCustomersShort: "Alcuni dei nostri clienti",
    titleOurValues: "I nostri valori",
    /* Generics */
    ctaEmailUs: `Contattaci! <div class="icon"><span class="icon-envelope-o"></span></div>`,
    ctaKnowMore: `Scopri di più! <div class="icon"><span class="icon-chevron-right"></span>`,
    /* Hero */
    heroH1: `Creiamo<br>prodotti<br><span data-typer="1000,grandi,1000,divertenti,1000,leggeri,1000,semplici,1000,per te"></span>
    <span class="cursor" data-owner="typer-products"></span>&nbsp;`,
    heroPhrase1: 'Costruiamo prodotti che la gente ama.',
    heroPhrase2: 'Hai grandi domande?',
    heroPhrase3: 'Abbiamo risposte ancora più grandi.',
    heroCtaLearnMore: 'Scopri di più! <div class="icon"><span class="icon-chevron-down">',
    /* Our Competences */
    ourCompetences1Title: 'Consulenza Direzionale',
    ourCompetences1Text: `Analizzando e comprendendo le tue esigenze aziendali, siamo in grado di sviluppare soluzioni che ti consentono di avere il controllo completo su tutta la tua catena di eventi: controllo di gestione, flusso e organizzazione dei processi, gestione del budget e tutto ciò che c'è nel mezzo.<br>Su misura per soddisfare le tue esigenze.`,
    ourCompetences2Title: 'Digital Transformation',
    ourCompetences2Text: `La Digital Transformation è l'uso della tecnologia per semplificare e migliorare i processi organizzativi e garantire una gestione aziendale più fluida e semplice. Possiamo aiutarti durante questo processo fornendo soluzioni dell'Industria 4.0 che puoi sfruttare per un migliore sviluppo organizzativo orizzontale e commerciale.`,
    ourCompetences3Title: 'ERP &amp; CRM',
    ourCompetences3Text: `Lavorando con soluzioni di livello mondiale come Microsoft Business Central e Dynamics NAV siamo in grado di fornire una gestione all'avanguardia per marketing, vendite, operazioni e finanza.<br>Inoltre, possiamo migliorare la customer experience tramite Dynamics 365 per il coinvolgimento dei clienti come soluzione CRM per la tua azienda.`,
    ourCompetences4Title: 'Data Analytics',
    ourCompetences4Text: `Le nostre soluzioni combinano la profonda conoscenza del business con le più moderne tecnologie e consentono, attraverso la raccolta e l'analisi dei dati aziendali, di migliorare il processo decisionale e creare valore nella tua organizzazione.`,
    ourCompetences5Title: 'System Integration',
    ourCompetences5Text: `Ti sei mai trovato nella situazione in cui non riesci a trovare una connessione tra i diversi processi della tua azienda? Offriamo soluzioni integrate che ti consentono di osservare e gestire la tua attività in modo chiaro, lineare e unificato.`,
    ourCompetences6Title: 'System Development',
    ourCompetences6Text: `Sviluppiamo applicazioni e prodotti pensati per soddisfare le tue esigenze. Durante questo processo, cerchiamo sempre di aiutarti a comprendere e identificare i tuoi reali problemi e ostacoli, utilizzando tecnologie all'avanguardia e la nostra esperienza per risolverli.`,
    /* Our Values */
    ourValues1Title: 'Approccio Agile',
    ourValues1Text: `Adottiamo un approccio Agile per lo sviluppo del nostro software in modo che i nostri requisiti e le nostre soluzioni evolvano attraverso lo sforzo collaborativo dei team auto-organizzanti e interfunzionali e voi, permettendovi di seguire la nascita del vostro prodotto e la sua crescita, dando la giusta impronta ad ogni passo.`,
    ourValues2Title: 'Garanzia di qualità',
    ourValues2Text: `Applicando tecnologia all'avanguardia e modelli di sviluppo di prim'ordine in ogni parte dei nostri prodotti, garantiamo qualità e resilienza ai vostri prodotti, consentendovi di continuare a utilizzare le nostre soluzioni in qualsiasi circostanza.`,
    ourValues3Title: 'Best Practices',
    ourValues3Text: `Applichiamo best practises e procedure consolidate in ogni aspetto del nostro lavoro. Dalla garanzia della qualità al controllo degli errori, dalle tecniche e roadmap di sviluppo all'esperienza utente. Creiamo prodotti per te di cui anche noi possiamo essere orgogliosi.`,
    ourValues4Title: 'Tecnologia Avanzata',
    ourValues4Text: `Utilizziamo solo le migliori tecnologie per offrirti la migliore esperienza possibile. Utilizzando strumenti all'avanguardia, offriamo soluzioni di grande valore, moderne e orientate al futuro. Con la nostra vasta gamma di stack di sviluppo, scegliamo i migliori strumenti possibili per costruire la tua soluzione.`,
    /* Our Products */
    ourProductsMyBizTitle: `Il miglior punto di accesso unificato per la tua azienda.`,
    ourProductsMyBizText: `Il framemork basato sul web per digitalizzare e consolidare tutti i processi aziendali e i moduli applicativi.<br>Gestione delle risorse umane, progetti, ordini, controllo di gestione, bilancio, documenti, flusso di lavoro, finanza, distribuzione, vendita al dettaglio e molto altro!`,
    ourProductsRetailTitle: `Una soluzione di vendita al dettaglio a tutti gli effetti per negozi grandi e piccoli.`,
    ourProductsRetailText: `Gestione multi-contanti e multi-valuta completa di un solido sistema di promozione, stampa e gestione delle fatture, carte fedeltà, storico scontrini, carte regalo, gestione dei resi e buoni pasto.<br>Costruito per essere solido, affidabile, scalabile, estremamente configurabile e facilmente personalizzabile.`,
    ourProductsAWTitle: `Una soluzione a 360 gradi per tenere il tuo business sempre in tasca.`,
    ourProductsAWText: `Dalla comunicazione all'orario di lavoro dei dipendenti, gestisci tutto dal telefono e non perdere mai il focus sulla tua attività.<br>Chatta con i tuoi dipendenti (sia tramite chat che VoIP), gestisci i lead, dai un'occhiata al tuo calendario, gestisci il tuo portafoglio e i tuoi progetti, guarda analisi di business e altro ancora!<br>Una soluzione su misura che fornisce system integration, gestione dei progetti, gestione delle relazioni con i clienti, reportistica e analisi. Tutto in un'unica app.`,
    /* News */
    newsAlexiaTitle: 'Orgogliosi di essere sponsor di Alexia Barrier durante il Vendée Globe.',
    newsAlexiaText: 'Alexia Barrier è sicuramente il riferimento assoluto in termini di tenacia. Quando si è impegnata nel suo progetto Vendée Globe 2020, conta in primis sulla sua immensa determinazione per iniziare. Ma questa giovane donna ha diverse risorse da mettere in campo: ha raccolto un gruppo di aziende, istituzioni e clienti attorno al suo progetto per acquisire nel febbraio 2018 Le Pingouin - l\'IMOCA navigato da Catherine Chabaud per il Vendée Globe del 2000.',
    newsSpatiTitle: '29-31 Gennaio 2020, la Fiera per i servizi e i prodotti per le aziende ticinesi.',
    newsSpatiText: `Al Centro Esposizioni di Lugano, via Campo Marzio, la 1a edizione di Spazio Ticino offre la possibilità ai professionisti in diversi settori, sia che operino in grandi aziende, o in piccole realtà o che siano consulenti e specialisti indipendenti, di trovare strategie, tecniche, metodi e contatti per
    fare decollare il proprio business. La manifestazione vedrà la presenza di più di 100 Aziende operanti sul territorio.<br>
    La nostra Società sarà presente, in collaborazione con Sisma Informatica S.A., presso lo Stand n°108 nell’area
    Information & Technology.<br>
    In occasione dell'evento avranno luogo numerose iniziative speciali:<br>
    <blockquote>
            8 Conferenze<br>
            10 Workshop<br>
            3 Importanti eventi: Team Building, Networking, confronto e scambio di opinioni.
    </blockquote>
       Partecipare a Spazio Ticino è la scelta giusta:
    <ul>
        <li>Per incontrare fornitori di prodotti e servizi visitando le aree tematiche della fiera.</li>
        <li>Per approfondire argomenti di sicurezza e gestione del rischio in azienda con relatori professionisti e workshop tematici.</li>
        <li>Per esplorare il mondo della comunicazione a 360° incontrando web agency, agenzie di comunicazione, e ascoltando le conferenze di esperti in comunicazione.</li>
        <li>Trovare partner, contatti e supporto per aprire o iniziare un’attività in Canton Ticino.</li>
        <li>Per confrontarsi con i professionisti di information&tecnology per rinnovare i propri processi aziendali, risparmiare risorse e aumentare la produttività.</li>
        <li>Per fare pubblicità meglio: più mirata e più remunerativa.</li>
        <li>Per trovare i fornitori ideali per realizzare eventi aziendali di sicuro successo.</li>
        <li>Per trovare ispirazioni, condividere idee e fare rete.</li>
        <li>Per risparmiare: trovi nuovi fornitori, servizi ottimizzati, risparmio energetico, piano assicurativo su misura e consulenze per risparmiare aumentando la produttività.</li>
    </ul>
    Avremmo il piacere di averti ospite presso il nostro stand nei giorni della fiera.<br>
    La partecipazione è subordinata alla registrazione on-line sul sito della manifestazione, al seguente link: <a href="https://www.spati.ch/" target="_blank">www.spati.ch</a>.<br><br>
    In attesa di incontrarti presso il nostro stand,<br>
    porgiamo i più cordiali saluti.<br><br>`,
    /* Footer */
    footerContactUs: `Contattaci <br class="hidden-on-desktop hidden-on-tablet">
    <a href="mailto:info@kfactor.ch" class="txt-primary">info@kfactor.ch</a>`,
    cookiePolicyBanner: `
    <div class="close-btn">x</div><br>
    Questo sito o gli strumenti terzi da questo utilizzati si avvalgono di cookie necessari al funzionamento ed utili alle finalità illustrate nella cookie policy. 
    Se vuoi saperne di più o negare il consenso a tutti o ad alcuni cookie, consulta la <a style="text-decoration:underline; color:#fff;  font-size:14px; font-weight:900; " href="./legal.html" target="_blank">cookie policy</a>.<br>
    Chiudendo questo banner, scorrendo questa pagina, cliccando su un link o proseguendo la navigazione in altra maniera, acconsenti all’uso dei cookie.
    `,
    modalTitle: 'Annuncio CoVid-19',
    modalText: `
    KFactor Solutions sagl informa i propri clienti che, nonostante l'emergenza CoVid-19, l'azienda rimane aperta e operativa al 100% per potervi supportare in questa delicata situazione.<br>
Per qualsiasi richiesta, anche solo di supporto o consiglio, siamo a vostra completa disposizione.
    `
}